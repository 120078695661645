import { store } from "../redux/app/store";
import axios from "axios";

// Base URLs
const BASE_URL = "https://apitest.fansupport.com";

// Create axios instances
export const fansupportPublic = axios.create({
  baseURL: BASE_URL,
});

export const fansupportPrivate = axios.create({
  baseURL: BASE_URL,
});

// Request interceptors for language and currency
const setLanguage = (config) => {
  config.headers["Accept-Language"] = "tr-TR";
  return config;
};

const setCurrency = (config) => {
  config.headers["x-currency"] = 949;
  return config;
};

// Authorization and token refresh logic
const setAuthorization = async (config) => {
  const user = store?.getState()?.user?.user;
  const token = user?.data?.accessTokenDto;
  const currentDate = new Date();

  if (token?.accessToken) {
    const expDate = new Date(token.expireDate);
    if (currentDate < expDate) {
      config.headers["Authorization"] = `Bearer ${token.accessToken}`;
      config.headers["x-currency"] = store?.getState()?.filter?.currency;
    } else {
      const refreshToken = token?.refreshToken;
      try {
        const response = await fansupportPublic.post("/identity/new-token", {
          value: refreshToken,
        });
        const newAccessToken = response?.data?.data?.accessToken;
        config.headers["Authorization"] = `Bearer ${newAccessToken}`;
        config.headers["x-currency"] = store?.getState()?.filter?.currency;
      } catch (error) {
        console.error("Token refresh error:", error);
      }
    }
  }

  return config;
};

// Applying interceptors to axios instances
fansupportPublic.interceptors.request.use(setLanguage, Promise.reject);
fansupportPublic.interceptors.request.use(setCurrency, Promise.reject);

fansupportPrivate.interceptors.request.use(setLanguage, Promise.reject);
fansupportPrivate.interceptors.request.use(setCurrency, Promise.reject);
fansupportPrivate.interceptors.request.use(setAuthorization, Promise.reject);
