import { fansupportPrivate } from "../index";
/**
 * Retrieve details of a notification.
 *
 * @async
 * @function getNotificationDetail
 * @param {number} notificationId - The ID of the notification to retrieve.
 * @return {Promise} A Promise that resolves to the details of the notification.
 * @throws {Error} If there was an error retrieving the notification.
 */
export const getNotificationDetail = async (notificationId) => {
    return fansupportPrivate.post('/notification/detail', {params: {
        notificationId,
    }})
}